import React from 'react';
import { useSelector } from 'react-redux';
import { Breadcrumbs } from 'wix-ui-tpa/cssVars';
import { useCurrentStateAndParams, useRouter } from '@wix/tpa-router/react';
import cls from 'classnames';

import { useTranslation } from '@wix/yoshi-flow-editor';

import { EFilterKeys } from 'api/feed/constants';
import { selectTopic } from 'store/selectors';

import { Box, type IBoxProps } from 'wui/Box';

import classes from './AppliedFilters.scss';

// TODO make it more "generic"
export const AppliedFilters = (props: IBoxProps) => {
  const { t } = useTranslation();
  const router = useRouter();
  const { params } = useCurrentStateAndParams();

  const topic = useSelector(selectTopic(params[EFilterKeys.TOPICS]));

  if (!params[EFilterKeys.TOPICS]) {
    return null;
  }

  return (
    <Box padding="SP0 SP5" {...props}>
      <Breadcrumbs
        className={classes.breadcrumbs}
        onClick={() => {}}
        items={[
          {
            id: 'reset',
            value: t('groups-web.discussion.title'),
            href: router.href(
              'group.discussion.feed',
              {
                ...params,
                [EFilterKeys.TOPICS]: undefined,
                [EFilterKeys.CURSOR]: undefined,
              },
              { absolute: true },
            ),
          },
          {
            id: 'current',
            value: t('groups-web.discussion.filters.topics', {
              topicName: topic?.displayName || params[EFilterKeys.TOPICS],
            }),
            href: router.href('group.discussion.feed', params, {
              absolute: true,
            }),
          },
        ]}
      />
    </Box>
  );
};

AppliedFilters.displayName = 'AppliedFilters';
